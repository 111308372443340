import { useState, useEffect, useCallback } from 'react';

const useFetchSelectorNoStorage = (url) => {
  const [data, setData] = useState([]);
  const fetchData = useCallback(async () => {
  
    try {
      const response = await fetch(url, {
        method: 'GET',
        body: null,
    });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      alert(`${url}: FAIL.!`);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data };
};

export default useFetchSelectorNoStorage;
