import useFetchPost from "../../hooks/useFetchPost";
import useFetch from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";

function ClientsList(props) {
	const { changeTab, usersSelected, url } = props;
	console.log(url);
	
	const [isActive, setIsActive] = useState(false);
	console.log(usersSelected);
	/*const [idActive, setIdActive] = useState(
		usersSelected ? usersSelected.id : ""
	);*/
	var currentArea = "";
	const endpoint = process.env.REACT_APP_API_URL + url;
	const {
		data: areas,
		loading,
		error,
		refetch,
	} = useFetch(endpoint);
	console.log(areas);

	if (loading)
		return <span className="loading loading-spinner loading-lg"></span>;

	if (error) console.error(error);

	const onChangeTab = (id, name) => {
		console.log(usersSelected);
		changeTab({ id: id, name: name });
		setIsActive(current => !current);
		//setIdActive(id);
		// other stuff
	};

	return (
		<div style={{ maxHeight: 'calc(100vh - 100px)' }} className=" overflow-y-scroll mr-4">
			<ul className="menu bg-base-100 divide-y divide-dashed flex flex-col">
				{areas &&
					areas.map((client, index) => (
						<li
										className="w-full text-xs"
										onClick={() => onChangeTab(client.id, client.label)}
									>
										{usersSelected?.id && usersSelected.id == client.id ? (
											<a className="active" data-id={client.id}>
												{client.label}
											</a>
										) : (
											<a data-id={client.id}>{client.value}</a>
										)}
									</li>
					))}
			</ul>
			</div>
	);
}

export default ClientsList;
