
import { useState } from 'react';
import NumericInput from '../inputs/NumericInput';
import useFetchClickFormFiles from '../../hooks/useFetchClickFormFiles';
import useFetchClickForm from '../../hooks/useFetchClickForm';

export default function AddAndUpdateImmexPedimentoPartnumber(props) {
    const { handleForm, dashboardRequest, url, title, startBody = {
        package_qty: 0,
        stock_qty: 0,
        cost: 0
    } } = props;
    const [misingsProps, setMisingsProps] = useState("")
    const [bodyFetch, setBodyFetch] = useState(startBody);

    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }
    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}${url}`,
        bodyFetch,
        dashboardRequest,
        handleForm
    )
    const verifyBody = () => {
        if (!bodyFetch.cost) setMisingsProps("Warning: Write a Cost!")
        else if (!bodyFetch.package_qty) setMisingsProps("Warning: Write a Package Qty!")
        else {
            setMisingsProps("")
            handleRequest()
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">{title}</h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <div className="mb-4">
                        <NumericInput
                            title="Package Qty"
                            keyName="package_qty"
                            selectorHandle={selectorHandle}
                            step="1"
                            min="0"
                            value={bodyFetch.package_qty} />
                    </div>
                    <div className="mb-4">
                        <NumericInput
                            title="Stock Qty"
                            keyName="stock_qty"
                            step="1"
                            min="0"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.stock_qty} />
                    </div>
                    <div className="mb-4">
                        <NumericInput
                            title="Cost"
                            keyName="cost"
                            step="0.0001"
                            min="0.0001"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.cost} />
                    </div>
                    {error && (<div role="alert" className="flex rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Write Fail</span>
                    </div>)}
                    {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span>{misingsProps}</span>
                    </div>)}
                    {
                        loading &&
                        (<progress className="progress w-full progress-info mb-4"></progress>)
                    }
                    <button
                        onClick={verifyBody}
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>

    );
};

