export default function BasicSelectorId(props) {
    const { title,value, keyName, selectorHandle, data } = props;
    return (
        <div className="form-control">
            <label for="large-input" class="block mb-2 text-sm font-medium text-black">{title}</label>
            <select
                className="input input-bordered border-gray-400"
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
            >
                {
                    data?.map((value, index) =>
                        <option key={index} value={value.id} label={value.name} />)
                }
            </select>
        </div>
    );
}