import { useState, useEffect, useCallback } from 'react';

const useFetchSelector = (url, propName='none') => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(url, {
        method: 'GET',
        body: null,
    });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const result = await response.json();
      sessionStorage.setItem(propName, JSON.stringify(result));
      setData(result);
    } catch (err) {
      alert(`${url}: FAIL.!`);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

};

export default useFetchSelector;
