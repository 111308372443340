import useFetch from "../../hooks/useFetch";
import PedimentoPartnumberRow from "./PedimentoPartnumberRow";
import { useEffect } from "react";

export default function PedimentoPartnumberTable({ refresh, partnumbersTable }) {

    useEffect(() => {
        partnumbersTable.handleRequest();
    }, [refresh])

    return (
        <div className="w-full p-4 rounded-lg border-4 border-gray-600">
            <h1 className="text-xl font-bold pb-2">
                Pedimentos and Partnumbers
                {
                    partnumbersTable.loading?
                    <span className="loading loading-spinner loading-sm ml-2"></span>:
                    null
                }
                </h1>
            <div className="overflow-x-auto border-solid border-2 border-black rounded-lg">

                <table className="table table-xs table-zebra">
                    <thead className="border-b-2 border-black">
                        <tr>
                            <th>Image</th>
                            <th>Partnumber</th>
                            <th>Package Qty</th>
                            <th>Stock Qty</th>
                            <th>Unit Cost</th>
                            <th>Pedimento</th>
                            <th>Import Date</th>
                            <th>Export Date</th>
                            <th>Metric Unit</th>
                            <th>Description</th>
                            <th>Hts</th>
                            <th>Location Tag</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            partnumbersTable.data.map((row, ind) =>
                                <PedimentoPartnumberRow
                                    row={row}
                                    ind={ind}
                                    dashboardRequest={partnumbersTable.handleRequest} />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}