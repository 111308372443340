import { useEffect, useState } from "react";
//import { getUserData } from "../../utils/utils";
import PartsTable from "../../components/tables/PartsTable";
import useFetch from "../../hooks/useFetch";
import AddAndUpdatePartnumber from "../../components/form/AddAndUpdatePartnumber";

export default function Partnumbers() {
    const [showEdit, setShowEdit] = useState(false);
    //const userInfo = getUserData();

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}get_partnumbers`, {})
    useEffect(() => {
        dataTable.handleRequest()
    }, [])

    return (
        <div className="w-full">
            <div className="py-2 flex">
                <button
                    onClick={() => setShowEdit(true)}
                    className="btn-sm btn btn-outline btn-success mr-2">
                    Add
                </button>
                <label className="input input-sm input-bordered flex items-center gap-2">
                    <input type="text" className="input-xs grow" placeholder="Search" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd" />
                    </svg>
                </label>
            </div>
            <PartsTable 
                data={dataTable.data}
                dashboardRequest={dataTable.handleRequest} />
            {
                showEdit?
                <AddAndUpdatePartnumber
                    handleForm={setShowEdit}
                    dashboardRequest={dataTable.handleRequest}
                    url="add_partnumber" />:null
            }
        </div>
    );
}