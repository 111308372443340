
import PartRow from "./PartRow";
export default function PartsTable({ data, dashboardRequest }) {
    return (
        <div className="overflow-x-auto border-solid border-2 border-black rounded-lg">
            <table className="table table-xs table-zebra">
                <thead className="border-b-2 border-black">
                    <tr>
                        <th>Id</th>
                        <th>Image</th>
                        <th>PartNumber</th>
                        <th>Description</th>
                        <th>Vendor</th>
                        <th>Manufacturer</th>
                        <th>Category</th>
                        <th>Link</th>
                        <th>Cost</th>
                        <th>Stock</th>
                        <th>Location</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, ind) =>
                            <PartRow 
                                row={row} 
                                ind={ind}
                                dashboardRequest={dashboardRequest}/>
                            )
                    }
                </tbody>
            </table>
        </div>
    );
}