
import { useState } from 'react';
import FileInput from '../inputs/FileInput';
import NumericInput from '../inputs/NumericInput';
import useFetchSelectorNoStorage from '../../hooks/useFetchSelectorNoStorage';
import BasicSelectorId from '../inputs/BasicSelectorId';
import TextInput from '../inputs/TextInput';
import useFetchClickFormFiles from '../../hooks/useFetchClickFormFiles';

export default function AddAndUpdatePartnumber(props) {
    const { handleForm, dashboardRequest, url, startBody = {
        location_tag: null,
        partnumber: null,
        description: null,
        link: null,
        category_id: 0,
        manufacturer_id: 0,
        vendor_id: 0,
        cost: 0,
        stock: 0,
        files: []
    } } = props;
    const [misingsProps, setMisingsProps] = useState("")
    const [bodyFetch, setBodyFetch] = useState(startBody);

    const vendorList = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_vendor_selector`)
    const manufacturerList = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_manufacturer_selector`)
    const categoryList = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_category_selector`)

    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }
    const { loading, error, handleRequest } = useFetchClickFormFiles(
        `${process.env.REACT_APP_API_URL}${url}`,
        bodyFetch,
        dashboardRequest,
        handleForm
    )
    const handleFilesChange = (event) => {
        selectorHandle("files", Array.from(event.target.files));
    }
    const verifyBody = () => {
        if (!bodyFetch.cost) setMisingsProps("Warning: Write a Cost!")
        else if (!bodyFetch.partnumber) setMisingsProps("Warning: Write a Partnumber!")
        else if (!bodyFetch.description) setMisingsProps("Warning: Write a Description!")
        else {
            setMisingsProps("")
            handleRequest()
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">Add Partnumber</h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <div className="mb-4">
                        <TextInput
                            title="Partnumber"
                            keyName="partnumber"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.partnumber} />
                    </div>
                    <div className="mb-4">
                        <TextInput
                            title="Description"
                            keyName="description"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.description} />
                    </div>
                    <div className="mb-4">
                        <TextInput
                            title="Link"
                            keyName="link"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.link} />
                    </div>
                    <div className="mb-4">
                        <TextInput
                            title="Location Tag"
                            keyName="location_tag"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.location_tag} />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Vendor"
                            keyName="vendor_id"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.vendor_id}
                            data={vendorList.data} />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Manufacturer"
                            keyName="manufacturer_id"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.manufacturer_id}
                            data={manufacturerList.data} />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Category"
                            keyName="category_id"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.category_id}
                            data={categoryList.data} />
                    </div>
                    <div className="mb-4">
                        <NumericInput
                            title="Stock"
                            keyName="stock"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.stock} />
                    </div>
                    <div className="mb-4">
                        <NumericInput
                            title="Cost"
                            keyName="cost"
                            selectorHandle={selectorHandle}
                            value={bodyFetch.cost} />
                    </div>
                    <div className="mb-4">
                        <FileInput
                            title="Load Image"
                            files={bodyFetch.files}
                            allowMultiple={false}
                            handleFilesChange={handleFilesChange} />
                    </div>

                    {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span>{misingsProps}</span>
                    </div>)}

                    <button
                        onClick={verifyBody}
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>

    );
};

