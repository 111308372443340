import { useState } from "react";
import { statusToColor } from "../../utils/constants";
import useFetchClick from "../../hooks/useFetchClick";
import useFetch from "../../hooks/useFetch";
import DeleteCard from "../form/DeleteCard";
import RejectCard from "../form/RejectCard";
import AddAndUpdateInvoice from "../form/AddAndUpdateInvoice";
import DocsViewer from "../form/DocsViewer";

import { format, parse } from "date-fns";
import { dateFormatDB } from "../../utils/constants";
import { separator } from "../../utils/utils";

export default function InvoiceCard(props) {
    const { cardData, isAdmin, dashboardRequest } = props;
    const [showDel, setShowDel] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [showDocs, setShowDocs] = useState(false);
    const cardId = cardData.id;
    console.log(cardData);
    const bodyFetch = { "cardId": cardId };
    const { loading: loadingApproved, error: errorApproved, handleRequest } = useFetchClick(
        `${process.env.REACT_APP_API_URL}approved_expense`,
        {
            "id": cardId
        },
        dashboardRequest
    )
    const { data: filesData, loading: filesLoading, error: filesError, handleRequest: handleGetFiles } = useFetch(
        `${process.env.REACT_APP_API_URL}expense_files`,
        bodyFetch
    )
    const approvedClickHandle = () => {
        handleRequest()
    }
    const rejectedClickHandle = () => {
        setShowReject(true);
    }
    const docsClickHandle = () => {
        setShowDocs(true);
        handleGetFiles();
    }

    return (
        <div className="w-full">
            <div className={`flex ${statusToColor[cardData.status]} rounded-lg drop-shadow-lg z-0`}>
                <div className="flex-none w-5" />
                <div className="grow bg-white p-4">
                    <div className="text-lg lg:text-2xl text-center text-black">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current mr-1 mb-1 text-blue-700"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"></path></svg>
                        {cardData.client_name}/{cardData.principal}
                    </div>
                    <div className="text-base lg:text-xl text-center text-black pb-2 border-b-2 border-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-fuchsia-600 rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path></svg>
                        {cardData.user_name}
                    </div>

                    <div className="flex flex-wrap py-2">
                        <div className="basis-1/2  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-green-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                            {separator(cardData.amount)}
                        </div>
                        <div className="basis-1/2  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-yellow-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"></path>
                            </svg>
                            {cardData.expense_datetime}
                        </div>
                        <div className="basis-1/2 text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-black bg-info rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4v3a1 1 0 0 1-1 1h-3m2 10v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L6.7 8.35A1 1 0 0 1 7.46 8H9m-1 4H4m16-7v10a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7.87a1 1 0 0 1 .24-.65l2.46-2.87a1 1 0 0 1 .76-.35H19a1 1 0 0 1 1 1Z"></path></svg>
                            {cardData.files_qty}
                        </div>
                        <div className="basis-1/2 text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-black rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"></path></svg>
                            {cardId}
                        </div>
                        <div className="basis-full text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-fuchsia-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"></path>
                            </svg>
                            {cardData.client_city}
                        </div>
                        <div className="basis-full text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-indigo-600 rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 5h6m-6 4h6M10 3v4h4V3h-4Z"></path></svg>
                            {cardData.expense_type}
                        </div>

                    </div>
                    <div className="basis-1/2 text-base">Expense Comment:</div>
                    <div className="bg-gray-900 rounded-lg p-2 text-white">{cardData.concept}</div>
                    {
                        cardData.denial_msg &&
                        (
                            <div>
                                <div className="basis-1/2 text-base  mt-2">Denial Comment:</div>
                                <div className="bg-red-900 rounded-lg p-2 text-white">{cardData.denial_msg}</div>
                            </div>
                        )
                    }
                    <div>
                        {
                            isAdmin && <>
                                {
                                    cardData.status !== 'APPROVED' &&
                                    (<button
                                        className="btn btn-square btn-success mr-4 mt-4"
                                        onClick={approvedClickHandle}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>
                                    </button>)
                                }
                                {
                                    cardData.status !== 'REJECTED' &&
                                    (<button
                                        onClick={rejectedClickHandle}
                                        className="btn btn-square btn-warning mr-4 mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>
                                    </button>)
                                }
                            </>
                        }
                        {((cardData.status !== "APPROVED") || isAdmin) &&
                            (<button
                                onClick={() => setShowUpdate(true)}
                                className="btn btn-square btn-info mr-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" /></svg>
                            </button>)
                        }
                        <button
                            onClick={docsClickHandle}
                            className="btn btn-square btn-info mr-4 mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4v3a1 1 0 0 1-1 1h-3m2 10v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L6.7 8.35A1 1 0 0 1 7.46 8H9m-1 4H4m16-7v10a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7.87a1 1 0 0 1 .24-.65l2.46-2.87a1 1 0 0 1 .76-.35H19a1 1 0 0 1 1 1Z" /></svg>
                        </button>

                        {((cardData.status !== "APPROVED") || isAdmin) &&
                            (<button
                                onClick={() => setShowDel(true)}
                                className="btn btn-square btn-error mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" /></svg>
                            </button>)
                        }

                    </div>
                    {errorApproved && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{errorApproved}</span>
                    </div>)}
                    {filesError && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{filesError}</span>
                    </div>)}
                    {
                        loadingApproved &&
                        (<progress className="progress w-full progress-info mt-4"></progress>)
                    }
                </div>
                <div className="flex-none w-5 " />
            </div>
            {showDocs &&
                (<DocsViewer
                    handleGetFiles={handleGetFiles}
                    data={filesData}
                    loading={filesLoading}
                    handleForm={setShowDocs}
                    isAdmin={isAdmin}
                    cardId={cardId}
                    cardStatus={cardData.status} />)
            }
            <DeleteCard
                show={showDel}
                handleForm={setShowDel}
                id={cardId}
                dashboardRequest={dashboardRequest}
                name="Expense" />
            <RejectCard
                concept= {cardData.concept}
                userId = {cardData.user_id}
                dt={format(parse(cardData.expense_datetime, 'dd/MM/yyyy', new Date()), dateFormatDB)}
                show={showReject}
                handleForm={setShowReject}
                cardId={cardId}
                dashboardRequest={dashboardRequest}
                name="Expense" />
            <AddAndUpdateInvoice
                show={showUpdate}
                handleForm={setShowUpdate}
                userName={cardData.user_name}
                dashboardRequest={dashboardRequest}
                status={cardData.status}
                startBody={{
                    "user_id":cardData.user_id,
                    "id": cardId,
                    "principal": cardData.principal,
                    "client_name": cardData.client_name,
                    "expense_type": cardData.expense_type,
                    "datetime_obj": parse(cardData.expense_datetime, 'dd/MM/yyyy', new Date()),
                    "datetime": format(parse(cardData.expense_datetime, 'dd/MM/yyyy', new Date()), dateFormatDB),
                    "amount": cardData.amount,
                    "concept": cardData.concept,
                    "denial_msg": cardData.denial_msg,
                    "files": []
                }}
                url="update_expense_files"
                title="Edit Expense"
                isAdmin={isAdmin}
                isRejected={cardData.status === "REJECTED"}
                datePickerHidden={true} />
        </div>

    );
}