import PartnumberRow from "./PartnumberRow";
export default function PartnumberTable({ data, dashboardRequest, loading }) {
    return (
        <div className="w-full p-4 rounded-lg border-4 border-gray-600">
        <h1 className="text-xl font-bold pb-2">
            Partnumber
            { loading?
                <span className="loading loading-spinner loading-sm ml-2"/>:
                null
            }
        </h1>
        <div className="overflow-x-auto border-solid border-2 border-black rounded-lg">
            <table className="table table-xs table-zebra">
                <thead className="border-b-2 border-black">
                    <tr>
                        <th>Image</th>
                        <th>PartNumber</th>
                        <th>Description</th>
                        <th>Metric Unit</th>
                        <th>Location Tag</th>
                        <th>HTS</th>
                        <th>Stock Qty</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, ind) =>
                            <PartnumberRow 
                                key={ind}
                                row={row} 
                                ind={ind}
                                dashboardRequest={dashboardRequest}/>
                            )
                    }
                </tbody>
            </table>
        </div>
        </div>
    );
}