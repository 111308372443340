import React, { useEffect } from 'react';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import useFetch from '../../hooks/useFetch';
import { useTypeKey } from '../../hooks/useTypeKey';
import { saveAs } from 'file-saver';
import { getUserData } from '../../utils/utils';

export default function ReportViewerMonth(props) {
    const { handleForm, filterValues, dashboardRequest } = props;
    const userInfo = getUserData();
    const getExpenseReport = useFetch(`${process.env.REACT_APP_API_URL}get_expense_report_month`,
        filterValues
    )
    const updateReportFetch = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}create_expense_report_month`,
        filterValues,
        dashboardRequest,
        handleForm
    )
    useEffect(() => {
        getExpenseReport.handleRequest();
    }, [])
    const typeKeyHandle = () => {
        handleForm(false);
    }
    const handleDownload = () => {
        const file2 = new Blob([new Uint8Array(getExpenseReport.data[0].file_content.data)]);
        saveAs(file2, getExpenseReport.data[0].file_name);
    };
    useTypeKey(typeKeyHandle, "Escape");
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Report Viewer</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                        <div className='py-4'>
                            {
                                userInfo.isAdmin ?
                                    <button
                                        onClick={() => updateReportFetch.handleRequest()}
                                        className="btn btn-warning mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                        </svg>
                                        Create Report
                                    </button> : null
                            }
                            {
                                getExpenseReport.data.length && getExpenseReport.data[0].file_name ?
                                    <button
                                        onClick={handleDownload}
                                        className="btn btn-info mr-6">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                        </svg>
                                        Download
                                    </button> : null}

                        </div>
                        {(!getExpenseReport.data.length && !getExpenseReport.loading) && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-info">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 shrink-0 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>There are No Files to show! </span>
                        </div>)}
                        {
                            getExpenseReport.data.length ?
                                (<div className='bg-gray-800 p-4 text-white rounded-lg mb-10'>
                                    <h1>Status:Ready to Download</h1>
                                    <h1>File Name:<span>{getExpenseReport.data[0].file_name}</span></h1>
                                    <h1>Report Id:<span>{getExpenseReport.data[0].expense_report_month_id}</span></h1>
                                    <h1>Month Num:<span>{getExpenseReport.data[0].month}</span></h1>
                                </div>) : null
                        }
                    </form>
                </div>
            </div>
        </>
    );
}


