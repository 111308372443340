import React from 'react';
import ComponentFileDb from './ComponentFileDb';
import { useTypeKey } from '../../hooks/useTypeKey';
import { saveAs } from 'file-saver';

export default function PartViewer(props) {
    const { handleForm, data, file_name } = props;
    const typeKeyHandle = () => {
        handleForm(false);
    }
    console.log(file_name)
    useTypeKey(typeKeyHandle, "Escape");
    console.log(data)
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Part Image</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                                <ComponentFileDb
                                    printRef={null}
                                    fileName={file_name}
                                    file={ data }
                                    fistPage={true}
                                    hoverColor={false} /> 
                    </form>
                </div>
            </div>
        </>
    );
}


